// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-business-intelligence-js": () => import("./../../../src/pages/business-intelligence.js" /* webpackChunkName: "component---src-pages-business-intelligence-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-govtech-js": () => import("./../../../src/pages/govtech.js" /* webpackChunkName: "component---src-pages-govtech-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pet-tech-js": () => import("./../../../src/pages/pet-tech.js" /* webpackChunkName: "component---src-pages-pet-tech-js" */),
  "component---src-pages-property-tech-js": () => import("./../../../src/pages/property-tech.js" /* webpackChunkName: "component---src-pages-property-tech-js" */)
}

